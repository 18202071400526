@font-face {
  font-family: 'NeurialGrotesk';
  src: url('../fonts/NeurialGrotesk-Regular.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'NeurialGrotesk';
  src: url('../fonts/NeurialGrotesk-Medium.otf') format('opentype');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'NeurialGrotesk';
  src: url('../fonts/NeurialGrotesk-Bold.otf') format('opentype');
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'NeurialGrotesk';
  src: url('../fonts/NeurialGrotesk-Extrabold.otf') format('opentype');
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: 'PingFang-SC';
  src: url('../fonts/PingFangSC.eot');
  src: url('../fonts/PingFangSC.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PingFangSC.woff2') format('woff2'), url('../fonts/PingFangSC.woff') format('woff'),
    url('../fonts/PingFangSC.ttf') format('truetype'),
    url('../fonts/PingFangSC.svg#PingFangSC') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --grid-item-spacing: 4;
  --grid-icon-size: 20px;

  /*Theme Variales*/
  --primary-color: #182470;
  --primary-light-color: #f5f7ff;

  --secondary-color: #e54696;
  --secondary-light-color: #ffeff7;

  --custom-background: #fafbff;
  --text-color-black: #000000;
  --text-color-10: #202b39;
  --text-color-1: #191919;
  --text-color-2: #222529;
  --text-color-4: #4e4e4e;
  --text-color-6: #6e6e6e;
  --text-color-8: #878e95;
  --text-color-a: #afafaf;
  --text-color-white: #ffffff;
  --text-color-tertiary: #8e959d;
  --text-color-warning: #ffb226;
  --text-color-success: #31a93e;
  --text-primary-dark: var(--primary-color);
  --text-color-primary: var(--primary-color);
  --text-color-secondary: var(-text-color-2);
  --text-color-light: var(-text-color-3);

  --error-red: #fe7777;
  --warning: #c64b00;
  --sucess: #31a93e;
  --info: #4dccff;

  --border-color-primary: var(--text-color-primary);
  --border-color-grey1: #bbbfc4;
  --border-color-1: #e8e8e4;
  --border-color-3: #f0f0f0;

  --main-background: #f7f7f7;
  --background-color-warning: #fff7e9;
  --background-color-success: #e9f6ea;
  --light-grey-bg: #f7f7f7;

  --link-color: var(--text-color-primary);

  --card-bg: #fff;

  --primary-icon-color: var(--primary-color);
  --primary-icon-bg-color: var(--primary-light-color);

  --top-header-bg: #fff;
  --top-header-shadow: rgba(222, 222, 222, 0.25) 0px 4px 22px 4px;

  --font-family-primary: 'NeurialGrotesk', 'Arial';

  /*Side Menu*/
  --side-menu-wrapper-bg: #fff;
  --side-menu-bg: var(--side-menu-wrapper-bg);
  --side-menu-hover-bg: var(--light-grey-bg);
  --side-menu-active-bg: var(--primary-light-color);
  --side-menu-link-color: var(--text-color-8);
  --side-menu-icon-color: var(--text-color-8);
  --side-menu-hover-link-color: var(--text-color-8);
  --side-menu-hover-icon-color: var(--text-color-8);
  --side-menu-active-link-color: var(--link-color);
  --side-menu-active-icon-color: var(--link-color);
  --side-menu-shadow: 0px 4px 22px 4px rgba(234, 234, 234, 0.25);

  /*Scrollbar*/
  --scroll-thumb: #ccc;
  --scroll-thumb-hover: #bbb;
  --scroll-track: var(--main-background);

  /*Chips*/
  --chip-green2-color: #00ca9a;
  --chip-green2-bg-color: hsla(166, 100%, 40%, 0.07);
  --chip-green-color: #31a93e;
  --chip-green-bg-color: rgba(49, 169, 62, 0.15);
  --chip-yellow-color: #f4ba62;
  --chip-yellow-bg-color: rgba(247, 206, 143, 0.15);
  --chip-red-color: #e96a6a;
  --chip-red-bg-color: rgba(233, 106, 106, 0.15);
  --chip-blue-color: #4dccff;
  --chip-blue-bg-color: rgba(77, 204, 255, 0.15);
  --chip-grey-color: #888;
  --chip-grey-bg-color: #f0f0f0;
  --chip-purple-color: #94aefa;
  --chip-purple-bg-color: rgba(148, 174, 250, 0.15);
  --chip-secondary-bg-color: #f9fafa;

  /*Tabas*/
  --tab-border: var(--border-color-3);
  --tab-active-border: var(--text-color-primary);
  --tab-color: rgba(58, 53, 65, 0.68);
  --tab-hover-color: var(--tab-color);
  --tab-disabled-color: var(--text-color-8);
  --tab-active-color: var(--tab-active-border);
  --tab-background: none;
  --tab-hover-background: none;
  --tab-active-background: none;
  --tab-disabled-background: none;

  /*Input Fields*/
  --input-background: #fff;
  --input-hover-background: var(--input-background);
  --input-border: var(--border-color-1);
  --input-border-hover: #888;
  --input-border-selected: var(--input-border-hover);
  --input-border-disabled: #e8e8e4;
  --input-icon-color: #222529;
  --input-disabled-icon-color: var(--text-color-8);
  --input-text-color: var(--text-color-2);
  --input-disabled-text-color: var(--text-color-8);
  --input-border-radius: 2px;

  /*Drawer*/
  --drawer-bg: #ffffff;
  --drawer-shadow: 0 4px 22px 4px hsla(0, 0%, 92%, 0.25);

  --top-loader-background: linear-gradient(
    92.42deg,
    #6a2b6e 0%,
    #9b3581 10%,
    #e84680 60%,
    #ec614e 100%
  );

  /*Buttons*/
  --button-border-radius: 2px;
  --primary-btn-background: linear-gradient(
    92.42deg,
    #181b4e -20.53%,
    #9b3581 18.39%,
    #e84680 54.73%,
    #ec614e 97.97%
  );
  --secondary-btn-background: #ffffff;
  --secondary-btn-filled-background: var(--primary-icon-bg-color);
  --secondary-btn-filled-color: var(--text-color-primary);
  --secondary-btn-hover-filled-background: var(--secondary-btn-filled-background);
  --secondary-btn-hover-filled-color: var(--secondary-btn-filled-color);

  /*Icon Gradien*/
  --gradient-color-blue: #181b4e;
  --gradient-color-purple: #9b3581;
  --gradient-color-pink: #e84680;
  --gradient-color-orange: #ec614e;
}
.text-primary {
  background: var(--top-loader-background) !important;
  color: var(--bs-heading-color) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}
.text-danger {
  color: var(--error-red) !important;
}
.text-alert {
  color: var(--warning) !important;
}
.text-sucess {
  color: var(--sucess) !important;
}
body[theme] {
  --top-loader-background: var(--secondary-color);
  --primary-btn-background: var(--primary-color);

  /*Icon Gradien*/
  --gradient-color-blue: var(--secondary-color);
  --gradient-color-purple: var(--secondary-color);
  --gradient-color-pink: var(--secondary-color);
  --gradient-color-orange: var(--secondary-color);
}

body {
  background: var(--main-background);
  * {
    font-family: var(--font-family-primary) !important;
  }
}
.headerAlexi {
  height: 72px;
  padding: 10px 0;
  border-bottom: 1px solid var(--border-color-1);
  background-color: #fff;
  display: flex;
  align-items: center;
  > .container {
    max-height: 100%;
    height: 100%;
  }
  img {
    height: auto;
    max-height: 51px;
  }
  .symbol-circle {
    align-self: center;
  }
}
.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 60px;
  @media (max-width: 1200px) {
    padding: 0 30px;
  }
  @media (max-width: 767px) {
    padding: 0 15px;
  }
  @media (max-width: 360px) {
    padding: 0px;
  }
}

/* Primary Button (variant="contained")  */
.button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  grid-gap: 4px;
  gap: 4px;
  padding: 9px 20px !important;
  border: none;
  min-width: 100px;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  cursor: pointer;
}

.button-primary {
  background: var(--primary-btn-background) !important;
  mix-blend-mode: normal;
  color: var(--text-color-white) !important;
}

.button-outline-back {
  background: var(--secondary-btn-background) !important;
  mix-blend-mode: normal;
  border: 1px solid var(--border-color-grey1) !important;
}

.button-primary[disabled] {
  opacity: 0.5;
}

.button-success {
  background-color: var(--sucess);
  color: #ffffff;
}

.button-reset {
  display: block;
  float: right;
  margin-right: 1.5rem !important;
}
.button-secondary-filled {
  box-shadow: none !important;
  color: var(--secondary-btn-filled-color) !important;
  border: none !important;
  background: var(--secondary-btn-filled-background) !important;
}
.button-secondary-filled:hover {
  color: var(--secondary-btn-hover-filled-color) !important;
  border: none !important;
  background: var(--secondary-btn-hover-filled-background) !important;
}
.button-secondary-outlined {
  box-shadow: none !important;
  background: none !important;
  border: 1px solid var(--border-color-1) !important;
  color: var(--text-color-8) !important;
}
.button-secondary-outlined:hover {
  background: none !important;
  border: 1px solid var(--border-color-1) !important;
  color: var(--secondary-btn-filled-color) !important;
}
/* Secondary Button (variant="outlined")  */
.button-secondary {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 12px !important;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e8e8e4 !important;
  border-radius: 2px;
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #888888 !important;
  text-transform: none !important;
  white-space: nowrap;
}
.button-disabled {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 12px !important;
  width: 100%;
  height: 40px;
  gap: 4px;
  background: var(--primary-btn-background) !important;
  mix-blend-mode: normal;
  border-radius: 2px;
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--text-color-white) !important;
  text-transform: none !important;
  white-space: nowrap;
  opacity: 0.7;
}
.success {
  color: var(--sucess) !important;
}
.warning {
  color: var(--warning) !important;
}
.error {
  color: var(--error-red) !important;
}
.section-heading {
  color: var(--text-color-1);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 24px;
  h2 {
    color: var(--text-color-1);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
  p {
    font-size: 12px;
    color: var(--text-color-6);
    font-weight: lighter;
    margin-top: 5px;
  }
  li {
    font-size: 12px;
    color: var(--text-color-6);
    font-weight: lighter;
    margin-top: 5px;
  }
}
.section-heading--small {
  font-size: 16px;
}

.bank-statement-upload {
  color: var(--text-color-1);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  background-color: var(--custom-background) !important;
  text-align: justify;
  line-height: 2;
  padding: 15px;
  font-size: 16px !important;
  ul li {
    font-size: 16px !important;
  }
}

.cardNew {
  padding: 24px;
  background: var(--card-bg);
  border-radius: 4px;
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
}
.form-control.form-control-solid,
.react-datepicker__input-container input {
  background: none !important;
  font-size: 14px;
  color: var(--input-text-color);
  font-weight: normal;
  border: 1px solid var(--border-color-1);
  border-radius: 0;
  padding: 8px 14px;
  line-height: 26px;
  min-height: auto;
  outline: none;
  &[disabled],
  &[readonly] {
    color: var(--input-disabled-text-color);
  }
  &:hover {
    border-color: var(--input-border-hover);
  }
  &:focus {
    border-color: var(--input-border-selected);
  }
}

.form-check.form-check-solid .form-check-input:checked {
  background-color: var(--primary-color) !important;
}
.form-check-input:checked {
  background-color: var(--primary-color) !important;
  border: var(--primary-color) !important ;
  background-size: 40% !important;
  width: 20px;
  height: 20px;
}

.form-check-input:not(checked) {
  width: 20px;
  height: 20px;
}

.icon-text {
  display: flex;
  align-items: center;
  gap: 2px;
  color: #616b75;
}

.upload {
  position: relative;
  border-radius: 6px;
  background-color: transparent;
  padding: 10px;
  // margin-right: 20px;
  & input[type='file'] {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 45px;
    opacity: 0;
    cursor: pointer;
  }
  & label {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 16px;
    width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.int-phone-input {
  border: 1px solid var(--border-color-1);
  border-radius: 0;
  background: none !important;
  .PhoneInputInput {
    border-radius: 0;
    padding: 8px 14px;
    font-size: 14px;
    line-height: 26px;
    color: var(--input-text-color);
    font-weight: normal;
    background: none !important;
    min-height: initial;
    border-width: 0px 0px 0px 1px;
  }
}
.input-field-label {
  margin-bottom: 8px;
  color: #000000;
  font-size: 14px;
}

.rangeSlider-labels {
  display: flex;
  justify-content: space-between;
  color: var(--text-color-6);
  font-size: 12px;
}
input[type='range'] {
  -webkit-appearance: none !important;
  &::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    background: var(--primary-color) !important;
  }
  &::-moz-range-thumb {
    -webkit-appearance: none !important;
    background: var(--primary-color) !important;
  }
  &::-ms-thumb {
    -webkit-appearance: none !important;
    background: var(--primary-color) !important;
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none !important;
    background: var(--border-color-3) !important;
  }
  &::-moz-range-track {
    -webkit-appearance: none !important;
    background: var(--border-color-3) !important;
  }

  &::-ms-track {
    -webkit-appearance: none !important;
    background: var(--border-color-3) !important;
  }
}
.text-grey-8 {
  color: var(--text-color-8) !important;
}

.file-uploader {
  color: var(--text-color-6) !important;
  font-size: 16px !important;
  text-decoration: none;
}

.file-uploader-sub {
  color: var(--text-color-tertiary) !important;
  font-size: 12px !important;
  text-decoration: none;
}

.react-calendar-datetime-picker .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-color) !important;
  padding: 1.5rem 1rem !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  position: relative !important;
  left: auto !important;
}

.react-calendar-datetime-picker .input-picker .input-picker--input {
  text-align: left;
  background: #f5f8fa;
  border: 0;
  font-size: 14.95px;
  padding: 12px 8px;
  height: auto;
}

.react-calendar-datetime-picker .daysList .is-disabled {
  background-color: #efefef;
  color: #959595;
}

.react-calendar-datetime-picker .daysList .is-selected-day {
  background-color: var(--primary-color) !important;
  color: white;
}

.react-calendar-datetime-picker .daysList .is-today {
  box-shadow: inset 0px 0px 0px 2px var(--secondary-color);
}

.react-datepicker-time__header {
  color: white !important;
}

//

.react-datepicker-wrapper,
.react-datepicker__input-container,
input {
  width: 100%;
}

.react-datetime-picker__calendar--open {
  inset: 100% auto auto auto !important;
}

.react-datepicker__input-container,
input {
  &[disabled] {
    color: rgba(94, 98, 120, 0.4) !important;
  }
}
.react-datepicker__input-container {
  padding: 0;
}
.text-primary-dark {
  color: var(--text-primary-dark) !important;
}

.react-datepicker__month {
  padding: 10px;
  font-size: 14px;
  line-height: 1;
  color: #959595;
}

.react-datepicker__header {
  background-color: var(--primary-color) !important;
  padding: 1.5rem 1rem !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  font-size: 14px;
  color: white !important;
}

.react-datepicker__input-container input {
  text-align: left;
  border: 1px solid var(--border-color-1) !important;
  font-size: 14.95px;
  padding: 8px 8px;
  height: auto;
}

.react-datepicker__day-name,
.react-datepicker__current-month {
  color: white;
}
.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  background: white;
}
.react-datepicker__month-option,
.react-datepicker__year-option {
  color: black;
}

.react-datepicker__navigation--years-previous {
  color: #464e5f;
}

.react-datepicker__year-select,
.react-datepicker__month-select {
  background: transparent;
  border: none;
  color: white;
}

.react-datepicker__month-select option,
.react-datepicker__year-select option {
  color: black;
}

.react-datepicker__year-select:focus,
.react-datepicker__month-select:focus {
  outline: none !important;
  box-shadow: none !important;
}

.badge {
  background-color: var(--chip-secondary-bg-color) !important;
}

.verify-profile {
  background-color: var(--secondary-light-color) !important;
  border: 1px dashed var(--secondary-color) !important;
  svg path {
    fill: var(--secondary-color) !important;
  }
}
.verify-profile-click {
  background-color: var(--secondary-light-color) !important;
  border: none !important;
  svg path {
    fill: var(--secondary-color) !important;
  }
}
.text-color-10 {
  color: var(--text-color-10) !important;
}
.verify-profile-manual {
  background-color: var(--chip-secondary-bg-color) !important;
  border: 1px dashed #a5aab0;
}

.table-header {
  background-color: var(--chip-secondary-bg-color) !important;
  border: 1px solid #e8eaeb;
  align-items: center;
  justify-content: center;
}

.table-wrapper {
  max-width: 100%;
  width: auto;
  overflow-x: auto;
}
.table {
  width: 100%;
  padding: 20px;
  font-size: 14px;
}

.table-bordered {
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #e8eaeb;
}

.table-bordered-bottom {
  border-width: 0px 0px 1px 0px !important;
  border-style: solid !important;
  border-color: #e8eaeb !important;
}
.table-shareholder-wrapper {
  width: auto;
  overflow: auto;
}

@media (max-width: 768px) {
  .table-wrapper {
    max-width: 300px;
    width: auto;
    overflow-x: auto;
  }

  .table-shareholder-wrapper {
    width: auto;
    overflow: auto;
  }
}
.badge {
  width: 63px !important;
  height: 28px !important;
  padding: 8px 8px !important;
  gap: 8px !important;
  border-radius: 8px 0px 0px 0px !important;
  opacity: 1 !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.badge.badge-warning {
  background: var(--background-color-warning) !important;
  color: var(--text-color-warning) !important;
}
.badge.badge-success {
  background: var(--background-color-success) !important;
  color: var(--text-color-success) !important;
}
.kyc_review {
  color: var(--text-color-6) !important;
}

.custom-accordion {
  .accordion-button {
    font-size: 18px;
  }
}
.accordion-button {
  padding: 20px;
}
.custom-accordion {
  .accordion-header {
    .accordion-button {
      color: var(--text-color-2) !important;
      &:after {
        color: var(--text-color-2) !important;
      }
    }
    .accordion-button:not(.collapsed) {
      color: var(--text-color-primary) !important;
      &:after {
        background-image: var(--bs-accordion-btn-icon);
      }
    }
  }
}

.accordion-item {
  border: 1px solid var(--border-color-3) !important;
  margin-bottom: 10px !important;
}

.pincode-input-container {
  margin: 30px 0 0;
  text-align: center;
}
.pincode-input-container input {
  border-radius: 4px;
  padding: 20px;
  margin: 0px 2px;
  text-align: center;
  background: transparent;
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.bg-congratulations_box {
  background-color: var(--chip-secondary-bg-color) !important;
}
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

@media screen and (max-width: 768px) {
  .truncate-text {
    max-width: 60px;
  }
}

.truncate-text::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
  white-space: nowrap;
}
.selected_manual {
  background-color: var(--primary-light-color) !important;
}
.truncate-text:hover::after {
  visibility: visible;
  opacity: 1;
}

.line {
  position: relative;
  display: inline-block;
  padding: 0 10px;
}
.line::before,
.line::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 50%;
  border-top: 2px solid #fafbff;
  z-index: 0;
}

.line::before {
  left: 0;
}

.line::after {
  right: 0;
}
.zIndex-1 {
  z-index: 1;
}
.table-wrapper {
  table {
    thead {
      tr {
        th {
          &:first-child {
            padding-left: 0.75rem;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:first-child {
            padding-left: 0.75rem;
          }
        }
      }
    }
  }
}
.form-check-inline {
  display: flex;
  gap: 5px;
  input {
    border-radius: 3px !important;
    margin-top: 3px;
  }
}
.form-check-input[type='checkbox'] {
  &:not(.custom-checkbox) {
    border-radius: 3px !important;
  }
  &.custom-checkbox {
    &[disabled] {
      background-color: #888 !important;
    }
  }
}
.badge-light {
  background-color: var(--primary-light-color);
  color: var(--text-color-4);
}

.loan-application {
  > label {
    border: 1px dashed transparent !important;
    &.btn.btn-outline.btn-outline-dashed.btn-outline-default:hover:not(.btn-active):hover {
      background-color: var(--primary-light-color) !important;
    }
    &.verify-profile-click {
      border: 1px dashed var(--secondary-color) !important;
    }
    &.selected_manual {
      border: 1px dashed var(--primary-color) !important;
    }
  }
}
.table-shareholder-wrapper {
  table {
    tr {
      td,
      th {
        padding: 0.75rem !important;
      }
    }
  }
}
.bg-secondary {
  background: var(--primary-light-color) !important;
  color: var(--primary-color) !important;
}
.badge {
  border-radius: 2px !important;
  font-weight: normal;
}
.sgVendorStartBoxWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: -30px;
  margin-bottom: -20px;
}
.sgVendorStartBox {
  background-color: var(--main-background);
  padding: 20px;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  flex: 1;
  .right {
    flex: 1;
    h3 {
      font-size: 20px;
      color: var(--text-color-10);
      margin: 0;
      margin-bottom: 20px;
    }
    ul {
      padding: 0;
      display: block;
      margin-left: 20px;
      margin-bottom: 0;
      li {
        display: list-item;
        width: 100%;
        color: var(--text-color-6);
        font-size: 14px;
        margin-bottom: 8px;
      }
    }
  }
  &.verticle {
    flex-direction: column;
  }
}

.stepperNew {
  display: flex;
  padding: 5px 0;
  text-align: left;
  margin: 0;
  li {
    flex: 1;
    display: block;
    position: relative;
    overflow: hidden;
    .stepBox {
      display: inline-flex;
      gap: 12px;
      background-color: #fff;
      position: relative;
      z-index: 5;
      padding: 0px;
      align-items: center;
      .iconWrap {
        width: 40px;
        height: 40px;
        border-radius: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f9fafa;
      }
      .right {
        text-align: left;
        span {
          font-size: 12px;
          color: var(--text-color-8);
        }
        p {
          margin: 0;
          font-weight: 500;
          font-size: 16px;
          color: var(--text-color-6);
        }
      }
      @media (max-width: 1200px) {
        .right {
          > span {
            font-size: 9px;
          }
          p {
            font-size: 12px;
          }
        }
      }
      @media (max-width: 900px) {
        flex-direction: column;
        gap: 3px;
        width: 100%;
        .right {
          > span {
            display: none;
          }
          p {
            white-space: normal;
            text-align: center;
          }
        }
      }
      @media (max-width: 450px) {
        .right {
          display: none;
        }
      }
    }
    &.current .stepBox {
      .iconWrap {
        path {
          fill: var(--text-color-primary) !important;
        }
      }
      .right {
        p {
          color: var(--text-color-primary);
        }
      }
    }
    &.completed .stepBox {
      .right {
        p {
          color: var(--text-color-1);
        }
      }
    }
  }
}
.stepperNewWrap {
  position: sticky;
  top: 0;
  z-index: 9;
  box-shadow: 0px 4px 8px var(--main-background);
  transition: all ease 0.3s;
}

.stickeyFooter {
  position: sticky;
  bottom: 0;
  z-index: 9;
  box-shadow: 0px -4px 8px var(--main-background);
}
.spinner-grow {
  background-color: var(--secondary-color) !important;
}

.welcomePage {
  background-color: #fff;
  min-height: calc(100vh - 72px);
  .banner {
    border-radius: 8px;
    background-color: var(--secondary-light-color);
    position: relative;
    margin-bottom: 50px;
    margin-top: 50px;
    overflow: hidden;
    text-align: center;
    padding: 40px 60px;
    .graphic {
      position: absolute;
      right: 0;
      bottom: 0;
      opacity: 0.3;
    }
    .icon {
      display: block;
      margin: auto;
      width: 60px;
      height: 60px;
      border-radius: 500px;
      background-color: #fff;
      padding: 15px;
    }
    h3 {
      font-size: 29px;
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 10px;
      background: var(--top-loader-background);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }
    p {
      font-size: 18px;
      color: var(--text-color-2);
      max-width: 600px;
      margin: auto;
    }
  }
  h6 {
    font-size: 24px;
    color: var(--text-color-1);
    text-align: center;
    margin-bottom: 50px;
    font-weight: 500;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    padding: 0;
    margin-bottom: 50px;
    li {
      flex: 1;
      display: block;
      label {
        padding: 24px;
        display: flex;
        border-radius: 8px;
        cursor: pointer !important;
        gap: 24px;
        border: 1px solid var(--border-color-1);
        transition: all ease 0.3s;
        min-height: 100%;
        h4 {
          font-size: 24px;
          margin: 0;
          font-weight: 500;
          margin-bottom: 10px;
          color: var(--text-color-black);
        }
        p {
          font-size: 16px;
          margin: 0;
          color: var(--text-color-8);
          font-weight: 100;
        }
        .icon {
          border-radius: 8px;
          background: #f9fafa;
          width: 64px;
          height: 64px;
          padding: 14px;
          transition: all ease 0.3s;
          svg path {
            transition: all ease 0.3s;
          }
        }

        &:hover {
          border-color: var(--primary-color);
          background: none;
          .icon {
            background: var(--top-loader-background);
            svg path {
              fill: #fff !important;
            }
          }
        }
        &.selected {
          background-color: var(--secondary-light-color);
          border-color: var(--primary-color) !important;
          .icon {
            background: var(--top-loader-background);
            svg path {
              fill: #fff !important;
            }
          }
        }
      }
    }
  }
  button.button-primary {
    float: right;
  }
}
.stepSection {
  h3 {
    font-size: 20px;
    text-align: center;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 35px;
    color: var(--text-color-6);
    font-weight: 500;
  }
  .stepBoxes {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    gap: 26px;
    max-width: 810px;
    margin: auto;
    align-items: stretch;
    justify-content: center;
    margin: auto;
    margin-bottom: 10px;
    &:before {
      content: '';
      position: absolute;
      height: 3px;
      background: var(--primary-light-color);
      top: 23px;
      z-index: 1;
      width: 66.66%;
    }
    .stepBoxWrap {
      flex: 1;
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      gap: 33px;
      justify-content: stretch;
      .icon {
        width: 52px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 100px;
        margin: auto;
        svg {
          position: relative;
          z-index: 3;
        }
        &:before {
          content: '';
          position: absolute;
          background: #f2f2f2;
          width: 1px;
          height: 23px;
          left: 0;
          right: 0;
          margin: auto;
          top: 52px;
        }
      }
      .stepBox {
        padding: 24px;
        border-radius: 7px;
        box-shadow: 0px 4px 4px #f3f3f3;
        background-color: #fff;
        border: 1px solid #f3f3f3;
        border-top: 2px solid var(--secondary-color);
        flex: 1;
        p {
          font-size: 12px;
          color: var(--text-color-8);
          margin-bottom: 5px;
        }
        h2 {
          color: var(--text-color-1);
          font-weight: bold;
          font-size: 20px;
          margin: 0;
          margin-bottom: 30px;
        }
        ul {
          padding-left: 20px;
          margin: 0;
          li {
            font-size: 14px;
            color: var(--text-color-6);
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.submittedThankYou {
  background: var(--top-loader-background);
  max-width: 800px;
  padding: 1px;
  border-radius: 12px;
  margin: auto;
  overflow: hidden;
  margin-bottom: 20px;
  ul {
    background-color: rgba(255, 255, 255, 0.93);
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    margin: 0;
    border-radius: 11px;
    li {
      text-align: center;
      display: block;
      flex: 1;
      &.center {
        border-left: 1px solid var(--text-color-a);
        border-right: 1px solid var(--text-color-a);
      }
      p {
        color: var(--text-color-2);
        margin-bottom: 5px;
      }
      h4 {
        font-weight: bold;
        font-size: 18px;
        margin: 0;
      }
    }
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 30px;
      li {
        &.center {
          border-width: 0px;
        }
      }
    }
  }
}
